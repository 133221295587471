<template>
  <content-layout :breadcrumbs-items="breadcrumbsItems" :fluid="true">
  </content-layout>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout.vue';
export default {
  name: 'CustomerAnalyticsPage',
  components: {
    ContentLayout
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.analyticalDataTitle'),
          disabled: true,
          exact: true
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped></style>
